import React, { useEffect, useState } from 'react'

const Automations = () => {

    return (
        <>
        <h1>Automations</h1>
        </>
    );
}
export default Automations;