import React from "react";
import BaseLayouts from "./Layouts/baselayouts";

function App() {
  return (
    <React.Fragment>
      <BaseLayouts></BaseLayouts>
    </React.Fragment>
  );
}

export default App;
